.side-nav-menu{
    display:flex;
    flex-direction: column;
    // padding-top: 50px;
    justify-items: center;
    align-items: center;
}

.side-nav-icons{
    font-size: 30px;
}

.ant-menu{
    background-color: transparent;
    padding: 0px;
    font-size:medium
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline{
    background-color: transparent;
}

.ant-menu-item{
    // background-color: red;
    justify-items: center;
    padding: 0px;
}

.side-menu-theme{
    color: white
    //text-align: center;
}

.contact-us-container-collapsed,
.contact-us-container {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
    position: fixed;
    bottom: 0;
    margin: 20px;
    >.ant-divider-horizontal {
		margin-top: 0;
	}

    .version {
        color: white;
        font-size: 12px;
        font-weight: normal;
    }

    .contact-us-wrapper {
        color: white;
        margin-bottom: 12px;
        >div {
            font-size: 18px;
            margin-bottom: 8px;
            >a {
                color: white !important;
            }
        }
        .contact-us-title {
            margin-bottom: 16px;
            display: flex;
            justify-content: flex-start;
            font-weight: bold;
            font-size: 24px;
        }
        .contact-us-icon {
            margin-right: 8px;
        }
    }

    .contact-us-collapsed {
        display: flex;
        flex-direction: column;
        color: white;
        .contact-us-collapsed-icon {
            cursor: pointer;
            margin-bottom: 8px;
        }
    }
}

.contact-us-container-collapsed {
	flex-direction: column;
	align-items: center;
}

@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;