.deposit-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    margin: auto;
    padding: 0px !important;

    .form-box {

        .balance-info-box {
            padding: 0% 3% 3% 3%;
            color: black;

            h4 {
                margin: 8px 0px 8px 0px;
            }
        }

        .grid-card {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .wallet-desc {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
        }
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;