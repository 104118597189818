::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #cccccc;
  border-radius: 10px;
  background: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

.ant-spin-nested-loading {
  .ant-spin {
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: @button-color;
      }
    }
  }

  .ant-spin-container::after {
    background: transparent;
  }
}

.ant-picker {
  width: 100%;
}

.form-box {
  max-width: 550px;
  width: 100%;
  min-width: 100px;
  padding: 0% 2%;
  height: 100%;

  // @media only screen and (max-width: 768px) {
  //     width: 90%;
  // }
}

.form-box-large {
  max-width: 700px;
  width: 100%;
  min-width: 100px;
  padding: 0% 2%;
  height: 100%;
  margin-bottom: 3%;

  // @media only screen and (max-width: 768px) {
  //     width: 90%;
  // }
}

.app-container {
  height: 100vh;
  overflow-y: hidden;

  .ant-layout-content {
    background-image: url(./assets/images/auth/auth-login-bg.png);
    background-size: 100% 100%;
  }

  .content-container {
    max-width: 1280px;
    max-height: inherit;
    padding: 15px 15px;
    margin: auto;
    max-height: 90vh;
    height: auto;
    overflow-y: auto;
  }
}

.site-layout-background {
  overflow-y: auto;
}

.ant-btn-primary {
  border-radius: 25px;
}

.request-otp-panel {
  display: flex;

  > div {
    flex: 1;
  }
}

.ant-layout-sider-children {
  background-color: @background-color-1;
}

body,
html {
  height: 100vh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

body,
html,
h1,
h2,
h3,
h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica", sans-serif;
}

// LEFT SIDE BLUE CARD CONTAINER
.card-container {
  // height: 100vh;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 26vw;
  min-width: 380px;
  background-color: #1d39c4;
  background-size: 100% 100%;
  padding: 1% 1%;

  @media only screen and (max-width: 768px) {
    max-width: 100vw;
    min-height: unset;
    width: 100%;
    height: auto;
    padding: 3%;
    background-image: none;
    min-width: unset;
  }

  // .card-info-container {
  //     @media only screen and (max-width: 768px) {
  //         display: flex;
  //         justify-content: space-between;
  //         align-items: stretch;
  //         padding: 0px 5px;

  //         h2 {
  //             margin-top: 0;
  //         }
  //     }
  // }

  .card-list-container {
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 100%;
    overflow-y: auto;
    height: 55%;
    min-height: 180px;
    margin-top: 5%;
    padding-right: 3px;
    padding-left: 3px;

    @media only screen and (max-width: 768px) {
      // flex-direction: row;
      // overflow-x: auto;
      // width: inherit;
      // height: fit-content;
      // max-height: 250px;
      // margin-top: 0;
      // padding-right: 0px;
      // padding-left: 0px;
      display: none;
    }
  }
}

#card-list-scroll::-webkit-scrollbar,
#card-list-scroll::-webkit-scrollbar-thumb,
#card-list-scroll::-webkit-scrollbar-track {
  @media only screen and (max-width: 768px) {
    display: none !important;
  }
}

.title-header-container {
  display: flex;
  flex-direction: column;
  padding: 1vh 2vw;
  margin: auto;
  background-color: white;
  height: 8vh;

  .title {
    flex: 1;
    font-weight: bold;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

#card-list-scroll::-webkit-scrollbar {
  height: 8px;
  width: 4px;
}

#card-list-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #cccccc;
  border-radius: 10px;
  background: transparent;
}

#card-list-scroll::-webkit-scrollbar-thumb {
  background: #d3d3d385;
  border-radius: 10px;
}

#card-list-scroll::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3;
}

.form-button {
  width: 100%;
  background-color: @button-color;
}

.btn-detail {
  border-radius: 25px;
}

.btn-cancel-verify {
  border-radius: 25px;
}

.btn-submit-verify, .btn-close-verify {
  background-color: @button-color;
}

.burse-logo {
  cursor: pointer;
}

.default-btn-class {
  background-color: @button-color !important;
}

.ant-btn-primary:disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.387) !important;
}

.card-menu-button {
  margin-right: 10px;
  height: 100%;
}

.card-menu-button {
  height: 35px;
}

.card-wrap {
  margin-bottom: 10px;
  color: white;
}

.page-content {
  height: 100vh;
  width: 100%;
}

.page-scroll {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.user-avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 10px 0px;
  margin-left: 24px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: 45px;
    height: 45px;
  }
}

.rule-box {
  width: 100%;
  margin-bottom: 16px;
  padding: 8px;
  background-color: #e6f7ff;
  border-color: #91d5ff;

  .rule-icon {
    padding-right: 4px;
    color: #1890ff;
  }

  .ant-card-body {
    padding: 0px;
    display: flex;

    @media only screen and (max-width: 768px) {
      font-size: 12px;
      text-align: justify;
      line-height: 1.3;
    }
  }
}

.disclaimer-box {
  margin-bottom: 0;

  .ant-card-body {
    display: flex;
    flex-direction: column;
  }

  .disclaimer-title {
    font-weight: bolder;
    font-size: 16px;
    margin-bottom: 4px;
  }

  .disclaimer-content {
    font-size: 12px;
    margin-left: 24px;
  }
}

.outlet-wrap {
  height: 100%;
  width: 100%;
}

.list-skele-large {
  margin: 15px 0px;
  padding: 0px 15px;

  .ant-skeleton-content {
    vertical-align: middle;
  }

  .ant-skeleton-header {
    .ant-skeleton-avatar-lg {
      width: 60px;
      height: 60px;
    }
  }
}

.list-skele {
  margin: 15px 0px;
}

.ant-skeleton-active {
  .ant-skeleton-content {
    .ant-skeleton-title {
      background: linear-gradient(
        90deg,
        #fff2f04a 25%,
        #fff2f07d 37%,
        #fff2f04f 63%
      );
      background-size: 400% 100% !important;
    }
  }
}

.skeleton-style {
  .ant-skeleton-content {
    .ant-skeleton-title {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      height: 24px !important;
    }
  }
}

.skeleton-small {
  .ant-skeleton-content {
    .ant-skeleton-title {
      height: 20px !important;
      max-width: 50%;
    }
  }
}

.ant-popover > .ant-popover-content {
  max-width: 350px;
  // font-size: 12px;
}

.statistic-wrapper {
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  .statistic-content {
    padding: 0px 10px;
    min-width: 120px;
    text-align: center;
    margin: 0px 25px;
    @media only screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      margin:0px;
      width: 100%;
      justify-content: space-between;
    }
  }

  .ant-divider-vertical{
    height:auto;
  }

  
}

.txn-item{
  cursor: pointer;
}

.header-profile {
  display: flex;
  flex-direction: row;
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;