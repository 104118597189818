.marquee-elements {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: @background-color-1;
    width: 100%;

    .announcement-icon {
        max-height: 35px;
    }

    .marquee-container {
        color: @text-color-1;
        min-height: 35px;
        gap: 30px;
    
        .overlay:after,
        .overlay:before
        {
            background: none;
        }

        .marquee {
            .home-marquee-content {
                margin-right: 30px;
            }
        }
    }
}

@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;