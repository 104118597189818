.card-action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    Button {
        margin: 0 8px;
        min-width: 150px;
    }
}

.change-atm-pin-title {
    font-weight: bolder;
    color: @primary-color;
}

.pin-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pin-title {
        font-size: 12px;
        margin-top: 12px;
        
    }
    .pin-input {
        padding: 0 2px;
        input {
            width: 2.5rem !important;
            height: 3rem;
            margin: 5px;
            font-size: 1.5rem;

            @media only screen and (max-width: 426px) {
                width: 1.8rem !important;
                height: 2.5rem;
                margin: 2px;
                font-size: 1rem;
            }

            @media only screen and (max-width: 300px) {
                width: 24px !important;
            }
        }
    }
}

.checking-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 16px;
    font-size: 10px;
    margin-top: 4px;
    @media only screen and (min-width: 769px) {
        font-size: 12px;
    }
}

.checking-box-error {
    color: red;
}

.checking-box-success {
    color: green;
}

.btn-send-code-wrapper {
    display: flex;
    margin-top: 16px;
    justify-content: center;
    .btn-send-code {
        background-color: @primary-color;
        border-radius: 8px;
        width: 150px;
    }
    .btn-send-code:disabled {
        background-color: grey;
    }
}

@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;