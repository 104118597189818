@defaultCardWidth: 350px;
@defaultCardHeight: 220px;

@smallCardWidth: 260px;
@smallCardHeight: 160px;

.wallet-card-container {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        margin-bottom: 0;
    }

    .emphasis-font {
        font-size: 20px;
        font-weight: 600;

        @media only screen and (max-width: 1024px) {
            font-size: 18px;
        }
    }

    .focus-font {
        font-size: 14px;
        font-weight: 600;

        @media only screen and (max-width: 1024px) {
            font-size: 12px;
        }
    }

    .small-font {
        font-size: 12px;
        font-weight: 200;

        @media only screen and (max-width: 1024px) {
            font-size: 10px;
        }
    }

    .no-card-container {
        // background-size: 100% 100%;
        background-size: cover;
        border: none;
        border-radius: 10px;
        background-repeat: no-repeat;
        width: @defaultCardWidth;
        height: @defaultCardHeight;
        background-color: transparent;

        @media only screen and (max-width: 768px) {
            background-size: cover;
        }

        @media only screen and (max-width: 360px) {
            background-size: cover;
            background-position: center;
            height: @smallCardHeight;
            width: @smallCardWidth;
        }

        .no-card-content {
            font-size: 14px;

            @media only screen and (max-width: 360px) {
                font-size: 10px;
            }
        }
    }


    .visa-card-container-blur,
    .visa-card-container {
        // background-size: 100% 100%;
        background-size: cover;
        border: none;
        border-radius: 10px;
        background-repeat: no-repeat;
        width: @defaultCardWidth;
        height: @defaultCardHeight;
        background-color: transparent;

        @media only screen and (max-width: 768px) {
            background-size: cover;
        }

        @media only screen and (max-width: 360px) {
            background-size: cover;
            background-position: center;
            height: @smallCardHeight;
            width: @smallCardWidth;
        }

        .visa-card-content {
            @media only screen and (max-width: 360px) {
                font-size: 12px;

                Button {
                    font-size: 12px;
                }
            }
        }
    }

    .visa-card-container-blur {
        filter: blur(4px);
    }

    .lock-card-wrapper {
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bolder;
        font-size: 18px;

        .lock-card-icon-wrapper {
            padding: 12px;
            background-color: white;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            margin-bottom: 8px;
            display: flex;
            justify-content: center;
        }
    }

    .wallet-card-background {
        background-size: 100% 100%;
        background-color: transparent;
        border: none;
        width: @defaultCardWidth;
        height: @defaultCardHeight;
        background-repeat: no-repeat;
        color: white;
        border-radius: 10px;

        @media only screen and (max-width: 360px) {
            height: @smallCardHeight;
            width: @smallCardWidth;
        }

        .ant-card-body {
            padding: 0;
            height: 100%;
            width: auto;
        }

        .card-content {
            padding: 5% 5%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: stretch;
            justify-content: space-between;
            height: inherit;

            @media only screen and (max-width: 280px) {
                padding: 7%;
            }

            .upper-content {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .balance-box {
                    display: flex;
                    flex-direction: column;
                }
            }

            .bottom-content {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .upper-box {
                    width: 100%;

                    .card-hr {
                        width: 100%;
                        border-top: 0.5px dashed white;
                    }
                }

                .lower-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    width: 100%;

                    .balance-box {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }
}

.card-info {
    .ant-statistic-title {
        font-size: 12px;
    }

    .ant-statistic-content {
        font-size: 14px;
    }
}

.card-skele {
    .ant-skeleton-content {
        .ant-skeleton-title {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
        }
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;