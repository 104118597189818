.topup-info-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    margin: auto;
    overflow: auto;

    .form-box {
        .title-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .wallet-card {
            padding: 0% 3% 3% 3%;
            margin-bottom: 5px;

            .ant-card-body {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }

        .grid-card {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .wallet-desc {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
        }

        .topup-input {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            justify-content: space-evenly;

            .amount-input {
                display: flex;
                gap: 10px;
                justify-content: center;
                width: 100%;
                align-items: center;

                @media only screen and (max-width: 320px) {
                    flex-direction:column;
                    gap: 10px;
                }
            }

            .ant-form-item {
                margin-bottom: 0;

                .ant-form-item-row>.ant-form-item-control>.ant-form-item-control-input>.ant-form-item-control-input-content {
                    text-align: center;
                }
            }
        }
        .top-up-button{
            //margin:10px 0px;
            width:100px;
        }

        .flex-top-up-buttons{
            margin:15px 0px;
            display:flex;
            width: 340px;
            height: 80px;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
        }

        .action-button {
            background-color: @button-color;
        }
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;