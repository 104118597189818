.home-page-container {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

    .home-info-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0;
        flex-direction: column;

        .padding-container{
            padding:2vh;
            width: 100%;
        }


        .indicator-box {
            width: 100%;
            display: flex;
            margin-bottom: 12px;
            flex-wrap: wrap;

            .tag-style {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 35px;
                font-size: 16px;
                cursor: pointer;
                color: black;
                margin-bottom: 5px;
                // background-color: #1D39C4;
                // color: white;
                // border-color: #1D39C4;
                // background-color: white;
                // color: #1D39C4;

                @media only screen and (max-width: 768px) {
                    height: 25px;
                    font-size: 12px;
                }
            }
        }
    }
}

.feature-container {
    width: auto;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    overflow-y: auto;
    padding: 10px;

    @media(max-width:768px) {
        display: grid;
        column-gap: 10px;
        row-gap: 15px;
        grid-template-columns: 230px 230px;
        overflow-y: auto;
    }

    .feature-button {
        width: 221px;
        height: 64px;
        border-radius: 20px;
        border: 0px;
        margin-right: 10px;
        background-color: white;
        box-shadow: 1px 2px 9px #bebcbc;
        display: flex;
        align-items: center;
        padding-left: 20px;
        text-align: start;
    }

    .feature-icon {
        margin-right: 10px;
    }
}

.feature-block {
    width: 221px;
    height: 64px;
    justify-content: center;
    border-radius: 20px;
    margin-right: 10px;
    box-shadow: 1px 2px 9px #bebcbc;
    text-align: start;
}

.quick-transfer-container {
    width: 342px;
    height: 513px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px;
    border-radius: 20px;
    box-shadow: 1px 2px 9px #bebcbc;
    background-color: white;
}

.label-font {
    color: rgba(38, 38, 38, 1);
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 5px;
}

::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar-thumb {
    display: none;
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;