.label-col{
    text-align: start;
    color: #808080d6;
    margin-bottom: 4px;
}

.value-col{
    text-align: end;
    font-weight: 600;
    margin-bottom: 4px;

    @media only screen and (max-width: 576px) {
        text-align: start;
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;