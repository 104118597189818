.transfer-out-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    margin: auto;
    padding: 0px !important;

    .form-box {
        .title-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .balance-info-box {
            padding: 0% 3% 3% 3%;
            color: black;

            h4 {
                margin: 8px 0px 8px 0px;
            }
        }

        .wallet-desc {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
        }
    }

    .transfer-out-input {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        justify-content: space-evenly;

        .address-input {
            margin-bottom: 16px;
            .ant-input-group-addon {
                background-color: @button-color;
            }
    
            .scan-button {
                background-color: transparent;
                color: #fff;
            }
        
            .scan-button,
            .scan-button:hover {
                border-color: transparent;
            }
        }
        
        .amount-input {
            display: flex;
            gap: 10px;
            justify-content: center;
            width: 100%;
            align-items: center;

            @media only screen and (max-width: 320px) {
                flex-direction:column;
                gap: 10px;
            }
        }

        .ant-form-item {
            margin-bottom: 0;
            .ant-form-item-row>.ant-form-item-control>.ant-form-item-control-input>.ant-form-item-control-input-content {
                text-align: center;
            }
        }
    }
    
    .action-button {
        margin-top: 16px;
        background-color: @button-color;
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;