@white: white;
@buttonColor: @button-color;
@form: 400px;

.auth-content {
    display: flex;
    height: auto;
    background-color: @white;

    @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        overflow-y: auto
    }

    .auth-form {
        flex: 7;
        display: flex;
        flex-direction: column;
        background: white;

        .auth-language-switcher{
            display: flex;
            flex-direction: row;
            justify-content:flex-end;
            margin: 14px;
        }

        .outlet-container{
            flex:1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        >div {
            min-width: 350px;
        }

        .ant-steps {
            visibility: hidden;
        }

        @media only screen and (max-width:768px) {
            // width: 100%;
            // border-radius: 32px 32px 0px 0px;
            // margin-top: -13vh;
            // z-index: 5;
            display: none;
        }

        .auth-header {
            display: flex;
            justify-content: space-between;
            padding: 20px;

            @media only screen and (max-width:576px) {
                padding: 20px 12px;
            }

            .auth-lang,
            a {
                padding: 10px;
                color: blue;
            }

            img {
                padding: 10px;
                width: 100px;
            }
        }

        .auth-footer {
            position: absolute;
            color: white;
            right: 0;
            bottom: 0;
            padding: 8px;

            @media only screen and (max-width: 768px) {
                position: initial;
                margin-top: auto;
                align-self: center;
                color: lighten(black, 50%);
            }
        }

        background-image: url(../../assets/images/auth/auth-login-bg.png);
        background-size: 100% 100%;
    }

    .auth-landing {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: flex-start;
            height: 40%;
        }

        background-image: url(../../assets/images/auth/auth-login-bg2.png);
        background-size: 100% 100%;

        .language-switcher-box{
            padding:18px;
            color:white;
            font-size: 32px;
            display:flex;
            justify-content: flex-end;
            width:100%;
        }

        .auth-landing-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 70%;
            width: 55%;
            font-size: large;
            color: @white;
            justify-content: space-around;
            text-align: center;

            .logoBox {
                @media only screen and (max-width: 375px) {
                    font-size: small;
                }
            }

            .logoClass {
                padding: 10px;
                width: 100%;

                @media only screen and (max-width: 768px) {
                    height: 70%;
                }
            }

            .auth-line {
                font-size: medium;

                @media only screen and (max-width: 375px) {
                    font-size: small;
                }
            }

            .auth-box {

                @media only screen and (max-width: 768px) {
                    display: flex;
                    background-color: #FAF9F6;
                    border-radius: 25px;
                    padding: 5%;
                    color: black;
                }

                @media only screen and (max-width: 426px) {
                    padding: 2%;
                }
            }
        }
    }

    .auth-signup {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        min-height: 300px;

        @media only screen and (max-width: 768px) {
            min-height: 220px;
        }

        .otp-box {
            padding-left: 15px;
            padding-right: 15px;

            div {
                justify-content: center;
            }

            .otp-input {
                input {
                    width: 2.5rem !important;
                    height: 3rem;
                    margin: 5px;
                    font-size: 1.5rem;

                    @media only screen and (max-width: 426px) {
                        width: 1.8rem !important;
                        height: 2.5rem;
                        margin: 2px;
                        font-size: 1rem;
                    }

                    @media only screen and (max-width: 300px) {
                        width: 24px !important;
                    }
                }
            }
        }

        .otp-help-box {
            padding-top: 15px;
            padding-left: 5px;
            display: flex;
            align-items: center;

            @media only screen and (max-width: 768px) {
                font-size: 14px;
                flex-direction: column;
            }
        }

        .reset-button-bar {
            display: flex;
            flex-direction: row;
            justify-content: center;

            @media only screen and (max-width: 768px) {
                flex-direction: column-reverse;
            }
        }
    }

    //LOGIN /FORGOT_PASS / PASS_RECOVERY / OTP
    .auth-container {
        width: 350px;

        @media only screen and (max-width: 360px) {
            min-width: 270px;
            width: auto;
        }

        .auth-login-bg {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        .auth-title {
            font-weight: bold;
            font-size: 27.65px;
            padding: 15px;
            flex-direction: column;
            display: flex;

            @media only screen and (max-width: 768px) {
                font-size: 20px;
            }


            .sm-title {
                font-weight: normal;
                font-size: 16px;
                padding-top: 6px;
                color: lighten(black, 50%);

                @media only screen and (max-width: 768px) {
                    font-size: 12px;
                }
            }

            .back-icon {
                color: @primary-color;
                padding: 10px;
            }

            .check-icon {
                height: 60px;
                width: 60px;
            }
        }

        .login-form {
            padding: 15px;
            font-size: 16px;

            @media only screen and (max-width: 768px) {
                .ant-form-item-label {
                    padding-bottom: 0;

                    label {
                        font-size: 12px;
                    }
                }
            }

            .login-form-forgot {
                @media only screen and (max-width: 768px) {
                    font-size: 12px;
                }
            }

            .ant-form-item {
                margin-bottom: 10px;
            }

            input[type="text"] {
                font-size: inherit;
            }

            .login-error {
                margin-bottom: 10px;
            }

            .login-form-button {
                width: 100%;
                background-color: @buttonColor;
            }

            .info-form-button-wrap {
                width: 100%;
                display: flex;

                .info-form-button {
                    width: 100%;
                    background-color: @buttonColor;
                    margin-right: 5px;
                    margin-left: 5px;
                }

                .info-form-cancel-button {
                    width: 100%;
                    background-color: white;
                    color: @buttonColor;
                    margin-right: 5px;
                    margin-left: 5px;
                }
            }

            .pwd-submit-button {
                width: 100%;
                background-color: @buttonColor;
            }

            .pwd-cancel-button {
                width: 100%;
                background-color: white;
                color: @buttonColor;
                border-radius: 25px;

                @media only screen and (max-width: 768px) {
                    margin-top: 5px;
                }
            }

            .pwd-rule-box {
                width: 100%;
                margin-bottom: 15px;
                padding: 10px;
                background-color: #E6F7FF;
                border-color: #91D5FF;

                .ant-card-body {
                    padding: 0px;
                    display: flex;

                    @media only screen and (max-width: 768px) {
                        font-size: 12px;
                        text-align: justify;
                        line-height: 1.3;
                    }
                }
            }
        }
    }
}


.captcha-panel {
    display: flex;
    flex-direction: row;
    margin-top: 12px;

    .captcha-input {
        margin-bottom: 0;
        width: 100%;

        .ant-input {
            height: 35px;
        }
    }

    .captcha-refresh-btn {
        padding: 4px;
        height: 35px;
        border-radius: 4px;
        background-color: @button-color;
    }

    .canvas-number {
        background: white;
        height: 35px;
        cursor: pointer;
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;