.country-dropdown {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.country-flag{
    width: 25px;
    height: 15px;
}

.country-dialcode{
    padding-bottom: 5px;
    padding-left: 5px;
}

.country-selector{
    width: 110px;
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;