.otp-box {
    padding-left: 15px;
    padding-right: 15px;

    div {
        justify-content: center;
    }

    .otp-input {
        input {
            width: 2.5rem !important;
            height: 3rem;
            margin: 5px;
            font-size: 1.5rem;

            @media only screen and (max-width: 426px) {
                width: 1.8rem !important;
                height: 2.5rem;
                margin: 2px;
                font-size: 1rem;
            }

            @media only screen and (max-width: 300px) {
                width: 24px !important;
            }
        }
    }
}

.otp-help-box {
    padding-top: 15px;
    padding-left: 5px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 768px) {
        font-size: 14px;
        flex-direction: column;
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;