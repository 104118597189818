
// .slider-container {
//      //overflow-y: auto;
//      background-color: pink;

    .sider-container {
        width:100px;
        // padding: 12px 16px;
        //background-color:#2F54EB;
        //background-color:#cd2411;
        margin: 0;
        min-height: 100vh;
        >div{  
            height: auto;
        }
    }
//}

// .slider-container-collapse {
//     overflow-y: auto;

    .sider-container-collapse {
        //background-color:#2F54EB;
        padding: 4px 8px;
        height: 100vh;
        margin: 0;

        @media only screen and (max-width:576px) {
            padding: 0;
        }
    //}
}


.ant-menu-item,
.ant-menu-submenu-title {
    padding: 0px;
}

.ant-menu-inline,
.ant-menu-vertical {
    border-right: none;
}

.side-nav-header{
    height: 64px;
    display: flex;
    flex-direction: row;
    background-color: #1d39c4;
    align-items: center;
    justify-content: space-between;
    padding-inline: 20px;
    color:white;
    
    .full-logo-style{
        width:100px;
        height:75px;
        //margin-left:15px;
    }

    .collapsed-logo-style{
        width:35px;
        height:38px;
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;