.news-events-box {
    color: rgba(38, 38, 38, 1);
    font-weight: 700;
    font-size: 18px;

    .news-title {
        margin: 0px 2vh;
        margin-bottom: 12px;
    }

    .news-modal {
        width: 100%;
        height: 100%;
    }

    .news-carousel{
        .slick-dots li button:before {
            display: none;
        }
        margin-bottom: 12px;
        @media only screen and (min-width: 768px) {
            margin: 8px 16px;
            width: 700px;
        }

        @media only screen and (max-width: 768px) {
            width: 100vw;
        }
        
        .carousel-image{
            cursor: pointer;
             height: 100%;
             width: 100%;
             max-height: 200px;
        }
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;