.transfer-out-confirmation-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    margin: auto;
    overflow: auto;

    .form-box {

        h4{
            margin-top: 4px;
            margin-bottom: 4px;
        }

        .top-up-title{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap:4px;
        }

        .transfer-info-wrap {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: flex-start;
            flex-wrap: wrap;
            font-size: 16px;
        }

        .transfer-info-right {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-end;
            font-weight: 600;
            font-size: 18px;
        }

        .success-transfer-info{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content:space-between;
            font-weight: 600;
            font-size: 18px;
            // align-items:center;
            // justify-items:space-between;
        }

        .payment-info-wrap {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .trx-statistic{
            width:200px;

            .ant-skeleton-title{
                margin:0;
            }
        }
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;