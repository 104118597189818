.back-button-bar {
    width: 100%;
    height: 30px;
}

.news-container {
    flex-direction: row;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.news-cover {
    width: 100%;
    height: 100%;
    position: relative;
    min-width: 450px;

    @media only screen and (max-width: 768px) {
        min-width: unset;
    }

    .news-btn {
        position: absolute;
        top: 8px;
        left: 8px;
        background-color: @button-color;
        color: white !important;
        font-size: 16px;
        // padding: 12px 24px;
        border: none;
        cursor: pointer;
        // border-radius: 5px;
    }

    .news-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.news-content {
    padding: 5%;

    .news-header {
        .news-title {
            font-size: 18px;
            font-weight: 700;
        }

        .news-subtitle {
            padding-top: 5px;
            font-size: 14px;
            color: #8C8C8C;
            padding-bottom: 5px;
        }
    }

    .news-desc {
        margin-top: 20px;
        font-size: 16px;
        text-align: justify;
    }

}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;