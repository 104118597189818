.card-wallet-view {
    display: flex;
    height: 100%;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        overflow-y: auto;
    }
}

.card-container-header {
    color: white;
}

#card-top-nav-scroll::-webkit-scrollbar,
#card-top-nav-scroll::-webkit-scrollbar-thumb,
#card-top-nav-scroll::-webkit-scrollbar-track {
    display: none !important;
}

.card-wallet-top-nav {
    display: flex;
    padding: 2vh;
    width: 100%;
    margin: auto;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {
        overflow-x: auto;
    }

    .top-nav-card {

        .ant-card-body {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 5% 15% 5% 10%;

            .top-nav-content {
                display: flex;
                flex-direction: column;
                line-height: initial;
            }
        }
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;