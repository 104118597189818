.profile-dashboard-container {
    display: flex;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        overflow-y: auto;
        height: 100vh;
    }
}

// .home-reveal{
//     display: none;
//     @media only screen and (max-width: 768px) {
//         display: block;
//     }
// }

.icons-row {
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-between;
}

.user-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    flex-direction: row;
    text-align: start;
}

.divider {
    // border-color: rgb(61, 66, 226);
    border-color: white;
    margin: 5px 0px;
}

.wallet-balance-box {
    display: flex;
    flex-direction: row;
    text-align: start;
    font-size: 16px;
    color: white;
    padding: 5px;
    justify-content: space-between;
    align-items: center;

    .balance-text {
        font-size: 24px;
        font-weight: 700;
    }


    @media only screen and (max-width: 768px) {
        justify-content: space-evenly;
    }
}

// .wallet-balance-container {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     margin: 5px;

//     .wallet-balance-box {
//         display: flex;
//         flex-direction: column;
//         text-align: start;
//         font-size: 14px;
//         color: white;
//         padding: 5px;

//         .balance-text {
//             font-size: 20px;
//             font-weight: 700;
//         }
//     }

//     .wallet-box {
//         display: flex;
//         align-items: center;
//     }
// }

.icon {
    color: white;
    font-size: 30px;
}

.balance-info-box {
    padding: 5px;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
}

.bold-text {
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    text-align: start;
    color: white;
    padding: 5px 0px;
    min-height: 40px;
}

.scrollable-wallet-container {
    overflow: auto;
    padding: '0 15px';
    display: flex;
    justify-content: center;
}

#scrollableDiv::-webkit-scrollbar {
    display: none;
}

#scrollableDiv::-webkit-scrollbar-thumb {
    display: none;
}

#scrollableDiv::-webkit-scrollbar-track {
    display: none;

}

.add-new-wallet-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    border: 3px dashed seagreen;
    height: 180px;
    width: 300px;

    .plus-icons {
        color: seagreen;
        font-size: 35px;
    }

    .add-new-wallet-title {
        color: seagreen;
        font-size: large;
        text-align: center;
        padding: 10px;
    }
}

.card-carousel {
    width: 100%;
    height: auto;
    display: none;
    margin-bottom: 3%;
    min-height: 180px;

    @media only screen and (max-width: 768px) {
        display: block;
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;