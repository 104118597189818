.cash-wallet-view {
    display: flex;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
    }
}

.balance-padding {
    padding-top: 4px;
    > div {
        margin-bottom: 8px;
        .balance-subtitle {
            font-size: 12px;
        }
    
        .balance-number {
            font-size: 16px;
        }
    }
    
}

.balance-justify-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-btn-primary:not(:disabled):hover {
        background-color: @button-color;
    }
}

.balance-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start !important;

    .balance-wording {
        font-size: 30px;
        font-weight: 700;
    }

    .reveal-button {
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .reveal-button-2 {
        display: none;
        @media only screen and (max-width: 768px) {
            display: block;
        }
    }
}

.info-button-container {
    display: block;

    @media only screen and (max-width: 768px) {
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // align-items: flex-end;
        display: none;
    }

    .reveal-button {
        display: none;

        @media only screen and (max-width: 768px) {
            display: block;
        }
    }
}

.header-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.card-container-header {
    color: white;
}

#card-top-nav-scroll::-webkit-scrollbar,
#card-top-nav-scroll::-webkit-scrollbar-thumb,
#card-top-nav-scroll::-webkit-scrollbar-track {
    display: none !important;
}

.cash-wallet-top-nav {
    display: flex;
    width: 100%;
    // flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    @media only screen and (max-width: 426px) {
        align-items: center;
        flex-direction: row;
        justify-content: space-around;
    }

    .top-nav-card {
        width: 150px;
        margin-right: 5px;

        @media only screen and (max-width: 426px) {
            width: 100px;
        }

        @media only screen and (max-width: 320px) {
            width: 130px;
        }

        .ant-card-body {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 5% 2% 5% 10%;

            @media only screen and (max-width: 426px) {
                img {
                    height: 25px !important;
                }
            }

            .top-nav-content {
                display: flex;
                flex-direction: column;
                line-height: initial;

                @media only screen and (max-width: 426px) {
                    font-size: 12px;
                }
            }
        }
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;