.language-switcher-container {
    display: flex;
    align-items: center;
}

.language-switcher-divider {
    margin: 0;
    background-color: #d9d9d9;
}

.language-dropdown {
    width: 100px;
}

.language-dropdown .ant-select-selector {
    color: #000;
    border: 1px solid #fff !important;
}

.language-dropdown .ant-select-arrow {
    color:  #000;
}

.language-select-dropdown {
    min-width: 110px !important;
}

.language-dropdown-selection {
    cursor: pointer;
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;