.page-header-wrapper {
    width: 100%;
    padding: 0px 20px 0 20px;
    margin-bottom: 20px;

    Button {
        border-radius: 4px;
    }

    .ant-breadcrumb {

        .breadcrumb-span:hover {
            cursor: pointer;
            background-color: #E5E4E2;
        }

        span {
            color: #000000;

            &.ant-breadcrumb-link {
                a {
                    color: #000000;
                    opacity: 0.8;
                }
            }
        }
    }

    .title {
        padding-bottom: 10px;
        color: #000000;
        margin-top: 15px;
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;