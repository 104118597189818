.transaction-box {
    max-width: 1280px;
    min-width: 100px;
    // height: 100%;
    //padding: 0% 2%;
    flex: 1;
    width: 100%;
    height: inherit;

    .title-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.load-more-style{
    text-align: center;
    margin-top: 12px;
    height: 32px;
    line-height: 32px;
}

.deposit-list {
    overflow-y: auto;
    height: 50vh;

    .ant-list .ant-list-item {
        @media only screen and (max-width: 426px) {
            display: flex;
            flex-direction: column;
        }
    }

    .ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
        margin-top: 0px;
    }

    .ant-list .ant-list-item .ant-list-item-meta {
        align-items: center;

        @media only screen and (max-width: 426px) {
            width: 100%;
        }
    }

    .list-hr {
        visibility: hidden;

        @media only screen and (max-width: 426px) {
            width: 100%;
            background-color: #13C2C2;
            opacity: 0.3;
            visibility: visible;
        }
    }

    .info-span {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media only screen and (max-width: 426px) {
            width: 100%;
            align-items: flex-start;
        }
    }
}

.empty-box {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.txn-currency-transfer {
    font-weight: 700;
    color: #EE4B2B;

    .txn-currency-transfer-sign {
        vertical-align: 5%;
    }
}

.txn-currency-deposit {
    font-weight: 700;
    color:#389e0d;

    .txn-currency-deposit-sign {
        vertical-align: 5%;
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;