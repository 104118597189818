.profile-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    margin: auto;
    overflow: auto;
}

.profile-content-container {
    margin-top: 24px;

    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none;
    }

    .avatar-container {
        display: flex;
        flex-direction: column;

        .title-avatar {
            margin-bottom: 8px;
            flex: 1;
            flex-direction: column;
            font-weight: bold;
            font-size: 16px;
        }
    }

    .ant-form-item-label {
        min-width: 100px;
        display: flex;
    }

    .profile-same-row {
        display: flex;
        gap: 12px;

        @media only screen and (max-width:768px) {
            display: block;
        }

        .ant-form-item {
            flex: 1;
        }
    }

    .verified,
    .non-verified {
        width: auto;
    }

    .verified {
        color: white;
        background-color: #52C41A;
        cursor: default;
    }

    .text-area {
        width: 100%;
        resize: none;
        background-color: rgba(0, 0, 0, 0.04);
    }

    .verify-button {
        background-color: transparent;
    }

    .verify-button,
    .verify-button:hover {
        border-color: transparent;
    }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;