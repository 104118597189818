.giftcard-box {
  width: 100%;
  height: auto;
  display: block;
  margin-top: 3%;
  min-height: 180px;
  min-width: 100%;
  color: rgba(38, 38, 38, 1);
  font-weight: 700;
  font-size: 18px;
  max-width: 700px;

  .giftcard-title {
    margin: 0px 2vh;
    margin-bottom: 12px;
  }

  .giftcard-card {
    width: 100%;
    height: 100px;
    background-repeat: no-repeat;
    background-position: center;

    @media only screen and (min-width: 768px) {
        width: auto;
    }
  }
}

@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;