@buttonColor: #13C2C2;

.kyc-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  margin: auto;
  overflow: auto;
}

.input-field {
  width: 50%;
  text-align: start;
  margin-bottom: 15px;
}

.address-text-field {
  text-align: start;
}

.sample-img {
  width: 180px;
  height: 130px;
}

.subtitle {
  margin-top: 8px;
  font-size: 14px;
}

.kyc-content-container {
  margin-top: 24px;
}

.kyc-alert-container {
  margin-bottom: 24px;
}

.kyc-items{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.btn-back {
  background-color: white;
  color: @button-color;
  border-color: @button-color;
  border-radius: 25px;
}

.ol-decimal{
  list-style: decimal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.kyc-li{
  padding: 4px;
}

.form-box {

  .application-info-box {
    padding: 0% 3% 0% 3%;
    color: black
  }

  .application-desc {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 12px;
  }

  .grid-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@primary-color: #1890ff;@secondary-color: #D6B07F;@background-color-1: #255bee;@text-color-1: #FFFFFF;@button-color: #13C2C2;